<template>
    <div class="gradient-card">
        <div class="gradient-card-body px-3 py-2">
            <slot />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .gradient-card {
        padding: 4px;
            background: linear-gradient(
            to left,
            #3FC5E0 0%,
            #A67FF5 26.52%,
            #F46B77 84.34%,
            #FF691F 100%
        );
        border-radius: 8px;
    }

    .gradient-card-body {
        background-color: var(--color-cases-content-bg);
        border-radius: 4px;
    }
</style>
