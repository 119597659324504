import { render, staticRenderFns } from "./GradientCard.vue?vue&type=template&id=38684ab6&scoped=true"
import script from "./GradientCard.vue?vue&type=script&lang=js"
export * from "./GradientCard.vue?vue&type=script&lang=js"
import style0 from "./GradientCard.vue?vue&type=style&index=0&id=38684ab6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38684ab6",
  null
  
)

export default component.exports