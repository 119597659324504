<template>
    <button
        v-if="!isSelf"
        @click="toggleFollow"
        :class="stateClass"
        :aria-label="isFollowing ? $t('general.following') : $t('general.follow')"
    >
        <slot name="follow" v-if="!isFollowing" />
        <slot name="following" v-else />
    </button>
</template>

<script>
export default {
    name: "follow",

    props: ['modelName', 'modelId', 'toggleClass'],

    computed: {
        stateClass () {
            if (this.toggleClass) {
                return this.isFollowing ? this.toggleClass.following : this.toggleClass.follow;
            } else {
                return '';
            }
        },

        isSelf () {
            return this.$auth.user && this.$auth.user.id == this.modelId && this.modelName == 'user';
        },

        interestKey () {
            return this.modelName == 'company' ? 'companies' : 'specialists';
        },

        isFollowing () {
            let interestKey = this.interestKey;

            if (
                !this.$auth.user || !this.$auth.user.interests ||
                !this.$auth.user.interests[interestKey]
            ) return false;

            if (typeof this.$auth.user.interests[interestKey] === 'string') {
                return this.$auth.user.interests[interestKey] === this.modelId;
            }

            return this.$auth.user.interests[interestKey].indexOf(this.modelId) >= 0;
        },
    },

    methods: {
        async toggleFollow () {
            if (!this.$auth.user) {
                return this.$modalityStore.openModal({
                    type: 'drawer',
                    name: 'login',
                    message: 'login.401.follow'
                })
            }

            let error;

            let data = await this.$axios.$post('/user/follow', {
                method: this.isFollowing ? 'remove' : 'add',
                model:  this.modelName,
                id:     this.modelId
            }, {
                progress: false
            })
            .catch(e => { error = e; });

            if (error) {
                console.error(error);
                return false;
            }

            if (this.isFollowing) {
                this.$toastStore.add({
                    title: this.$t('toast.unfollow'),
                    icon: 'icon-ic-broken-heart'
                });
                this.$emit('unfollow');
            }
            else {
                this.$toastStore.add({
                    title: this.$t('toast.follow'),
                    icon: 'icon-ic-favourite'
                });
                this.$emit('follow');
            }

            // Update storage
            const user = Object.assign({}, this.$auth.user);

            user.interests = JSON.parse(JSON.stringify(user.interests));

            let comps = user.interests[this.interestKey];

            if (!comps) comps = [];
            if (typeof comps === 'string') comps = [comps];

            if (this.isFollowing) comps = comps.filter(id => id !== this.modelId);
            else comps.push(this.modelId);

            user.interests[this.interestKey] = comps;
            this.$auth.setUser(user);
        },
    }
}
</script>
