<template>
    <Marco>
        <template v-if="locale == 'uk'">
            <p>Привіт, {{$auth.user.name.givenName}}!</p>
            <p>{{ pageText }} Для твоєї зручності, ми розбили її на три вкладинки:</p>
            <ol>
                <li class="mb-1"><strong>Для вас.</strong> Сюди потрапить усе, що подобається тільки тобі. Для цього треба обрати коло своїх інтересів.</li>
                <li class="mb-1"><strong>Вибір редакції.</strong> Кожного дня наша редакція відбирає кращий контент на платформі та додає його сюди.</li>
                <li><strong>Все.</strong> Тут ми зібрали все, що публікують та розповідають наші користувачі без фільтрації та обмежень.</li>
            </ol>
            <p>Щоб отримати найкращий досвід на CASES, будь ласка, налаштуй свої інтереси. Це займе 5 хвилин.</p>

            <nuxt-link
                :to="localePath('/my/settings/interests')"
                class="btn-primary mt-3"
            >
                <span class="icon-ic-cog color-gray-3"/> Налаштувати інтереси
            </nuxt-link>
        </template>

        <template v-else>
            <p>Hi {{$auth.user.name.givenName}}!</p>
            <p>{{ pageText }} For your comfort, we have divided it into three tabs:</p>
            <ol>
                <li class="mb-1"><strong>For you.</strong> Everything you are interested in will be here. Just choose your interests.</li>
                <li class="mb-1"><strong>Editor's choice.</strong> Every day our editors selects the best content on the platform and adds it here.</li>
                <li><strong>All.</strong> Here is everything that our users publish and tells without filters and limits.</li>
            </ol>
            <p>For best CASES usage experience please set your interests. It will take only 5 minutes.</p>

            <nuxt-link
                :to="localePath('/my/settings/interests')"
                class="btn-primary mt-3"
            >
                <span class="icon-ic-cog color-gray-3"/> Set interests
            </nuxt-link>
        </template>
    </Marco>
</template>

<script>
import Marco from '~/components/cards/Marco.vue';

export default {
    components: {
        Marco
    },

    props: {
        page: {
            type: String,
            default: 'home'
        }
    },

    data () {
        return  {
            home: {
                ru: "Это главная страница CASES.",
                en: "This is CASES home page.",
                uk: "Це головна сторінка CASES."
            },
            cases: {
                ru: "Это страница со списком кейсов.",
                en: "This is cases list page.",
                uk: "Це сторінка зі списком кейсів."
            },
            articles: {
                ru: "Это страница со списком статей.",
                en: "This is articles list page.",
                uk: "Це сторінка зі списком статтей."
            }
        }
    },
    computed: {
        locale () {
            return this.$i18n.locale || 'uk';
        },

        pageText () {
            return this[this.page][this.locale];
        }
    }
}
</script>

<style lang="scss" scoped>
    .btn-primary {
        display: inline-block;
        width: auto;
    }
</style>
