<template>
    <Card>
        <div class="avatar-row mb-2">
            <div class="avatar-image avatar-person">
                <img
                    class="block-image f-shadow"
                    src="/images/avatar-marko.png"
                    alt=""
                    width="32"
                    height="32"
                >
            </div>
            <div class="avatar-name fw-700">
                {{ $t('marco.name') }}
            </div>

            <div class="tag blue fw-700 linked ml-2">
                {{ $t('marco.help') }}
            </div>
        </div>
        
        <slot />
    </Card>
</template>

<script>
import Card from '~/components/cards/GradientCard.vue';

export default {
    components: {
        Card
    },

    computed: {
        locale () {
            return this.$i18n.locale || 'uk';
        }
    }
}
</script>

<style lang="scss" scoped>
    .avatar-row {
        display:         flex;
        flex-flow:       row nowrap;
        justify-content: flex-start;
        align-items:     center;
    }

    .avatar-image {
        margin-right: 8px;

        flex: 0 0 32px;

        > img {
            border-radius: 4px;
        }

        &.avatar-person > img {
            border-radius: 50%;
        }
    }
</style>
